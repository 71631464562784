@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply h-auto md:h-screen;
}

body, #root {
  @apply h-svh w-full;
}

body {
  @apply font-open-sans;
  background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
}

main {
  @apply container pt-5 pb-10 md:py-20 max-w-screen-lg px-6 md:px-0;
}

nav {
  @apply container py-10 flex items-center justify-center;
}

h1 {
  @apply text-2xl md:text-3xl font-bold text-brand-gray;
}

h2 {
  @apply text-sm text-black opacity-50;
}

input {
  @apply border rounded-xl px-4 py-3 focus:outline-[#EADBC8];
}

label {
  @apply text-sm pb-2;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.bg-gradient {
  @apply rounded-b-xl md:rounded-r-xl md:rounded-l-none;
  background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
}